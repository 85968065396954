import request from '@/utils/request'
// 获取微信openid
export const getWechatInfo = (code) => {
  return request.get('/api/wechat/jsApi/pay/login/callback', {
    params: {
      code
    },
  }
  )
};

// 获取微信openid默认 不需要token
export const getWechatInfoTwo = (code) => {
  return request.get('/api/wechat/jsApi/pay/login/getOpenId', {
    params: {
      code
    },
  }
  )
};
// 获取微信openid默认 需要token
export const getWechatInfoThree = (code) => {
  return request.get('/api/wechat/jsApi/pay/openId', {
    params: {
      code
    },
  }
  )
};
// 存储UnionId
export const addUnionId = (openId, unionId) => {
  return request.post('/api/wechat/jsApi/pay/add/unionId', {}, {
    params: {
      openId,
      unionId
    },
  }
  )
};
// 获取授权判断
export const getWechatIf = () => {
  return request.post('/api/auth/wechat/booleanBind')
};

// 获取外授权判断
export const getWechatIfLodin = (openId) => {
  return request.post('/api/auth/wechat/booleanLogin', {}, {
    params: {
      openId
    }
  }
  )
};
// 解除绑定微信授权
export const relieveWechatIf = () => {
  return request.post('/api/auth/wechat/unbind')
};
// 绑定微信授权
export const bangWx = (headimgurl, openId, nickname, sex) => {
  return request.post('/api/auth/wechat/bind', {
    headimgurl,
    openId,
    nickname,
    sex
  },
  )
};
// 微信授权登录
export const wechatLogin = (phone, code, openId, headimgurl, nickname, sex) => {
  return request.post('/api/auth/wechat/login', {
    phone,
    code,
    openId,
    headimgurl,
    nickname,
    sex,
  }
  )
};
// 微信支付
export const PayInfo = (body, memberId, price, openId, couponId, originalPrice, discountAmount) => {
  return request.post('/api/wechat/jsApi/pay/createApiOrder', {
    body,
    memberId,
    price,
    openId,
    couponId,
    originalPrice,
    discountAmount
  },
  )
};
// 我的题库全部解锁
export const xwAllunlockList = (memberUtilsId, body, price, openId, couponId, originalPrice, discountAmount) => {
  return request.post('/api/wechat/jsApi/pay/utilsAllApiOrder', {
    memberUtilsId,
    body,
    price,
    openId,
    couponId,
    originalPrice,
    discountAmount
  },
  )
};
export const PayUtilsInfo = (body, memberUtilsId, price, openId, couponId, originalPrice, discountAmount) => {
  return request.post('/api/wechat/jsApi/pay/utilsApiOrder', {
    body,
    memberUtilsId,
    price,
    openId,
    couponId,
    originalPrice,
    discountAmount
  },
  )
};
export const PayOrderInfo = (orderId, openId) => {
  return request.post('/api/wechat/jsApi/pay/reinvokePayment', {}, {
    params: {
      orderId,
      openId
    },
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  },
  )
};

// 扫码记录
export const qrcodeAdd = (sceneName, openId) => {
  return request.post('/wechat/qrcode/add', {}, {
    params: {
      sceneName,
      openId
    },
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  },
  )
};
// 扫码记录
export const ifToken = (openId) => {
  return request.post('/api/auth/wechat/h5/login', {}, {
    params: {
      openId
    },
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  },
  )
};
// 分享获取参数
export const wxShare = (url) => {
  return request.post(`/wechat/share/signature`, {}, {
    params: {
      url
    }
  })
};
