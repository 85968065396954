import request from '@/utils/request'
// 获取用户基本信息
export const getUserInfo = () => {
  return request.post('/api/user/info')
};
// 查看用户会员账户，查看用户是否开通会员
export const getUserMemberInfo = () => {
  return request.post('/api/member/info')
};
// 会员倒计时
export const getUserMemberList = () => {
  return request.post('/api/member/list')
};
// 上传头像
export const ImageUpload = (file) => {
  return request.post('/api/user/upload',
    file,
    // {
    //   headers: {
    //     'appToken': getUserCode(),
    //     'Content-Type': 'multipart/form-data',
    //   }
    // }
  )
};
// 修改用户信息
export const informUpdate = (id, name, phone, sex, headimg, role, employer, center) => {
  return request.put('/api/user/update', {
    id,
    name,
    phone,
    sex,
    headimg,
    role,
    employer,
    center
  })
};
// 修改用户信息
export const changePhone = (phone, code, newPhone) => {
  return request.post('/api/updatePhone', {
    phone,
    code,
    newPhone
  })
};
// 修改用户信息
export const putPassword = (phone, password, code) => {
  return request.put('/api/update', {
    phone,
    password,
    code,
  },)
};
//设置密码
export const setPassword = (phone, password) => {
  return request.post('/api/updatePassword', {
    phone,
    password
  })
};
// 切换考试
export const updateExam = (examTypeId, classifyId) => {
  return request.put('/api/info/update', {
    examTypeId,
    classifyId
  }
  )
};
// 添加考试
export const AddExamType = (examTypeId, classifyId) => {
  return request.post('/api/info/add', {
    examTypeId,
    classifyId
  })
};
// 注销账户
export const tokenCancel = () => {
  return request.post('/api/cancel')
};
// 今日学习统计
export const getUserCount = () => {
  return request.post('/api/count/studyTime')
};
// 获取今日数据相关接口-知识页
export const getTodayCount = () => {
  return request.post('/api/count/today')
};
// 学习统计
export const getCountInfo = () => {
  return request.post('/api/count/studyDayInfo')
};
// 学习统计
export const getQuestionInfo = () => {
  return request.post('/api/count/studyQuestionInfo')
};

// 查看我的题库每日一练数据列表
export const getEverydayData = (current, size) => {
  return request.post('/api/question/one', {},
    {
      params: {
        current,
        size,
      },
    }
  )
};
// 查看我的题库每日一练题的数据详情
export const getEverydayDetail = (id) => {
  return request.post('/api/question/selectOne', {},
    {
      params: {
        id
      },
    }
  )
};
// 查看我的题库计时考试试卷数据列表
export const getTimerData = (current, size) => {
  return request.post('/api/question/twe', {},
    {
      params: {
        current,
        size,
      },
    }
  )
};
// 查看我的题库查看计时考试试题详情
export const getTimerDetail = (id, scoresId) => {
  return request.post('/api/question/selectTwe', {},
    {
      params: {
        id,
        scoresId
      },
    }
  )
};
// 查看我的题库历年题库试卷数据列表
export const getPreviousrData = (current, size) => {
  return request.post('/api/question/three', {},
    {
      params: {
        current,
        size,
      },
    }
  )
};
// 查看我的题库查看历年题库试题详情
export const getPreviousrDetail = (id) => {
  return request.post('/api/question/selectThree', {},
    {
      params: {
        id
      },
    }
  )
};
// 用户学习时常开始时间
export const setStudyStart = () => {
  return request.post('/api/study/start')
};
// 用户学习时常结束时间
export const setStudyEnd = () => {
  return request.post('/api/study/end')
};
// 用户手册-判断用户是否是第一次进行答题相关接口
export const getFirstLogin = () => {
  return request.post('/api/user/first-login')
};
// 更新相关接口
export const getUpdateFirst = () => {
  return request.post('/api/user/updateFirst')
};
// --------------------------------------------------我的错题--------------------------------------------------------
// 获取我的错题类别列表接口
export const getWrongTitle = () => {
  return request.post('/api/wrong/title')
};
// 获取我的错题高频错题数据列表接口
export const getOftenWrong = (current, size) => {
  return request.post('/api/wrong/errors', {}, {
    params: {
      current,
      size
    },
  }
  )
};
// 大学生  我的错题高频错题数据列表接口
export const getOftenWrongStu = (current, size) => {
  return request.post('/api/wrong/student/errors', {}, {
    params: {
      current,
      size
    },
  }
  )
};
// 大学生 获取我的错题试卷信息列表接口
export const getWrongPaperStu = (source, questionType, current, size) => {
  return request.post('/api/wrong/student/paper', {}, {
    params: {
      source,
      questionType,
      current,
      size,
    },
  }
  )
};
// 获取我的错题试卷信息列表接口
export const getWrongPaper = (source, questionType, current, size) => {
  return request.post('/api/wrong/paper', {}, {
    params: {
      source,
      questionType,
      current,
      size,
    },
  }
  )
};
// 大学生 获取我的错题试题列表接口
export const getWrongListStu = (paperId, source, questionType, current, size) => {
  return request.post('/api/wrong/student/list', {
    paperId,
    source,
    questionType,
  },
    {
      params: {
        current,
        size,
      },
    }
  )
};
// 获取我的错题试题列表接口
export const getWrongList = (paperId, source, questionType, current, size) => {
  return request.post('/api/wrong/list', {
    paperId,
    source,
    questionType,
  },
    {
      params: {
        current,
        size,
      },
    }
  )
};
//测试我的错题试卷试题列表接口
export const getWrongListTest = (questionId, answer) => {
  return request.post('/api/wrong/test', {}, {
    params: {
      questionId,
      answer
    },
  }
  )
};
// ----------------------------------------------------我的试卷---------------------------------------------------------
// 获取我的试卷标头数据列表接口
export const getPapertitle = () => {
  return request.post('/api/paper/title')
};
// 大学生  获取我的试卷信息数据列表接口
export const getPaperInfoStu = (id, questionType, current, size) => {
  return request.post('/api/paper/student/paperInfo', {}, {
    params: {
      id,
      questionType,
      current,
      size
    },
  }
  )
};
// 获取我的试卷信息数据列表接口
export const getPaperInfo = (id, questionType, current, size) => {
  return request.post('/api/paper/paperInfo', {}, {
    params: {
      id,
      questionType,
      current,
      size
    },
  }
  )
};
// 查看我的试卷考试结果数据列表接口
export const getPaperAnswerInfoStu = (id, paperId, scoresId) => {
  return request.post('/api/paper/student/questionInfo', {}, {
    params: {
      id,
      paperId,
      scoresId
    },
  }
  )
};
// 查看我的试卷考试结果数据列表接口
export const getPaperAnswerInfo = (id, paperId, scoresId) => {
  return request.post('/api/paper/questionInfo', {}, {
    params: {
      id,
      paperId,
      scoresId
    },
  }
  )
};


// ---------------------------------------------------个人信息报考地----------------------------------------
// 获取个人信息报考地接口数据
export const getApplyAddreesOne = () => {
  return request.post('/api/auth/list/unit/one',
  )
};
// 获取个人信息报考地接口数据二级
export const getApplyAddreesTwo = (provinceId) => {
  return request.post('/api/auth/list/unit/two', {}, {
    params: {
      provinceId
    }
  }
  )
};
// ------------------------------------------------------发票信息----------------------------------------
// 获取用户发票数据列表接口
export const getInvoicesList = (current, size) => {
  return request.post('/api/invoices/all', {}, {
    params: {
      current,
      size,
    },
  }
  )
};

// 获取用户发票数据--根据订单的订单号进行获取
export const getUserInvoices = (orderId) => {
  return request.post('/api/invoices/list', {}, {
    params: {
      orderId
    },
  }
  )
};
// 获取用户发票数据--根据订单的订单号进行获取
export const getDeleteOrder = (id) => {
  return request.post('/api/order/cancel', {}, {
    params: {
      id
    },
  }
  )
};
// 用户申请订单发票接口数据--根据抬头类型进行添加发票数据
export const getUserInvoicesAdd = (data) => {
  return request.post('/api/invoices/add', data
  )
};
// 电子发票发送邮箱接口数据--将开好的发票pdf文件发送到邮箱中
export const getUserInvoicesSend = (id) => {
  return request.post('/api/invoices/send', {}, {
    params: {
      id
    },
  }
  )
};



// ------------------------------------------------考试倒计时--------------------------------------------


// 添加用户倒计时
export const addExamTime = (examTime) => {
  return request.post('/api/study/add', {}, {
    params: {
      examTime
    }
  }
  )
};

// 修改用户倒计时
export const setExamTime = (examTime) => {
  return request.post('/api/study/update', {}, {
    params: {
      examTime
    }
  }
  )
};

// 获取用户倒计时
export const getExamTime = (examTime) => {
  return request.post('/api/study/select',
    examTime,
  )
};
// 优惠券数据相关接口
// ------------------------------------------优惠券--------------------------------------------------
// 1 可使用 2已使用 3已过期
export const getCouponList = (status) => {
  return request.post('/api/coupon/list', {},
    {
      params: {
        status,
      },
    }
  )
};
//  检查支付时订单有没有优惠券数据列表接口
export const getCouponCheck = () => {
  return request.post('/api/coupon/check')
};
//  选取优惠券后计算金额数据列表接口
export const getCouponDiscount = (price, couponId) => {
  return request.post('/api/coupon/discount', {},
    {
      params: {
        price,
        couponId
      },
    }
  )
};
// -------------------------------------------刷题位置-------------------------------------------
// 查询用户刷题位置
export const getProgressIndex = (subjectId) => {
  return request.post('/api/student/underline/progress/index', {},
    {
      params: {
        subjectId
      },
    }
  )
};
// 添加刷题位置
export const getProgressAdd = (subjectId, index) => {
  return request.post('/api/student/underline/progress/add', {
    subjectId,
    index,
  },
  )
};
// // 修改刷题位置
// export const getProgressUpdate = (subjectId, index) => {
//   return request.post('/api/student/underline/progress/add', {
//     subjectId,
//     index
//   },
//   )
// };
// 获取位置接口
export const getSubjectIndex = (subjectId) => {
  return request.post('/api/student/underline/two/progress/index', {},
    {
      params: {
        subjectId
      },
    }
  )
};
// 设置位置接口
export const setSubjectIndex = (subjectId, indexOne, indexTwo, questionId) => {
  return request.post('/api/student/underline/two/progress/add', {
    subjectId,
    indexOne,
    indexTwo,
    questionId
  },
  )
};
// 获取Token
export const getAppToken = (refreshToken) => {
  return request.post('/api/token', {},
    {
      params: {
        refreshToken,
      },
    }
  )
};



