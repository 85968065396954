import { wxShare } from "../api/wx";
import wx from 'weixin-js-sdk';
// data分享参数数据
// url当前页码地址
// content分享信息
// 获取分享参数并分享
export const sharePage = async (url, content) => {
  await wxShare(url).then(res => {
    if (res.code == 200) {
      share(res.data, url, content)
    }
  })
}
// 分享
const share = function (data, url, content) {
  // 确保在微信环境中
  if (typeof wx !== 'undefined' && wx !== null) {
    // 检查路由元信息中是否有微信分享配置
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: data.appId, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature,// 必填，签名
      jsApiList: [
        'updateTimelineShareData',// 分享到朋友圈
        'updateAppMessageShareData'// 分享给朋友
      ] // 必填，需要使用的JS接口列表
    });
    // 配置微信分享
    wx.ready(function () {

      wx.updateTimelineShareData({
        title: "昱京医学", // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "http://test.image.cdn.yujingt.cn/yujing_medicine/1732869100495-85bdb79b-c861-41f4-837c-24737d5c8025.png", // 分享图标
        success: function () {
          // 设置成功
        }
      })
      // ... 其他分享配置，如分享到朋友圈等
      wx.updateAppMessageShareData({
        title: "昱京医学",
        link: url,
        desc: content !== "昱京医学" ? content : "数据赋能 服务医学", // 分享描述
        imgUrl: "http://test.image.cdn.yujingt.cn/yujing_medicine/1732869100495-85bdb79b-c861-41f4-837c-24737d5c8025.png",
        success: function () {
        },
      });
    });

    // ... 其他分享配置，如分享到朋友圈等
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.error('微信JS-SDK配置错误:', res);
    });

  }
}