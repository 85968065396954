import request from '@/utils/request'
// 通知
export const getInform = (type, size, current) => {
  return request.post('/api/library/allList', {}, {
    params: {
      type,
      size,
      current
    }
  }
  )
};
// 新获取通知标题
export const getInformTitle = () => {
  return request.post('/api/library/title')
};
export const getNoticeDetail = (id) => {
  return request.post('/api/library/select', {}, {
    params: {
      id,
    }
  })
};
// --------------------------------------------搜索-------------------------------------------------------

export const searchName = (name) => {
  return request.post('/api/library/search', {}, {
    params: {
      name,
    }
  })
};
// 热搜
export const searchHot = () => {
  return request.post('/api/hot/search/list', {

  })
};

// -----------------------------------------------兑换码------------------------------------------------------
// 兑换码兑换相关接口
export const getRedemption = (code) => {
  return request.post('/api/redemption/redeem', {}, {
    params: {
      code
    },
    __isToast: true
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};
export function isToast(config) {
  return !!config.__isToast
}
// 查看兑换码兑换记录相关接口
export const getRedemptionRecord = () => {
  return request.post('/api/redemption/all', {}, {
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};
// ------------------------------------------------------------------------------------------------------
// 资格考试选择
export const getExamInfo = () => {
  return request.post('/api/info/new/examInfo', {}, {
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};
//单元考试选择
export const getUtilsInfo = (typeId) => {
  return request.post('/api/info/utilsInfo', {}, {
    params: {
      typeId,
    }
  })
};
//单元考试选择
export const getStudentClass = (typeId) => {
  return request.post('/api/classify/new/list', {}, {
    params: {
      typeId
    }

  })
};
// 轮播图
export const getCarousel = () => {
  return request.get('/api/carousel')
};
// // 每日一练数据接口
export const getPractice = () => {
  return request.get('/api/practice/daily/info', {}, {
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};
// 用户点击交卷获取答题结果
export const submitPracticeAnswer = (userExamInfoDto) => {
  return request.post('/api/practice/daily/examInfo',
    userExamInfoDto


  )
};
// 获取答题解析
export const getAnswerDetail = (questionId, examId) => {
  return request.post('/api/practice/daily/details', {}, {
    params: {
      questionId,
      examId
    },
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }

  }
  )
};
// 获取年份筛选条件数据列表
export const getFindYear = () => {
  return request.post('/api/yearUtilsInfo/findYear', {}, {
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};
//  获取单元筛选条件数据列表
export const getFindUtils = () => {
  return request.post('/api/yearUtilsInfo/findUtils', {}, {
    // headers: {
    //   'appToken': getUserCode(),
    //   'Examtypeid': getExamType(),
    //   'Classifyid': getUtilsType(),
    // }
  })
};





//  用户刷题记录表
export const getyearUtilsInfoAnswer = (userExamInfoDto, yearUtilsId, questionType) => {
  return request.post('/api/yearUtilsInfo/answer', userExamInfoDto, {
    // questionId,
    // answer,
    params: {
      yearUtilsId,
      questionType
    },
  },)
};

//------------------------------------------历年题库------------------------------------------------------
//获取历年考题模拟题经典题数据接口
export const getFindType = () => {
  return request.post('/api/yearUtilsInfo/findType')
};

//  查看历年题库试卷数据列表
export const getPreviousExam = (id, current, size,) => {
  return request.post('/api/yearUtilsInfo/all', {}, {
    params: {
      current,
      size,
      id
    },
  },

  )
};
//  查看历年题库试卷数据详情
export const getPreviousExamDetail = (id) => {
  return request.post('/api/question/selectThree', {}, {
    params: {
      id,
    },
  },

  )
};


//------------------------------------------会员-订单------------------------------------------------------


//  查询会员信息列表数据接口
export const getMemberInfo = (id) => {
  return request.post('/api/member/all')
};

//  查询已支付订单列表数据
export const getOrderList = (status) => {
  return request.post('/api/order/list', {}, {
    params: {
      status
    },
  },

  )
};
// //  查询已支付订单列表数据
// export const getOrderPaid = (current, size) => {
//   return request.post('/api/order/paid', {}, {
//     params: {
//       current,
//       size
//     },
//     headers: {
//       'appToken': getUserCode(),
//       'Examtypeid': getExamType(),
//       'Classifyid': getUtilsType(),
//     }
//   },

//   )
// };
//  待支付我的订单数据列表
export const getWaitPayOrder = (current, size) => {
  return request.post('/api/order/unpaid', {}, {
    params: {
      current,
      size
    },
  },
  )
};

//------------------------------------------我的错题数据相关接口------------------------------------------------------
// 大学生错题








//  获取每日一练错题试题数据
export const getWrongInfo = (current, size) => {
  return request.post('/api/wrong/all', {}, {
    params: {
      current,
      size
    },
  },)
};

//  获取每日一练，知识，计时考场错题数据
export const getWrongSource = (current, size, source) => {
  return request.post('/api/wrong/source', {}, {
    params: {
      current,
      size,
      source,
    },
  },)
};

//用户错题进行复习
export const getWrongPractice = (questionId, answer) => {
  return request.post('/api/wrong/practice', {}, {
    params: {
      questionId,
      answer
    },
  },)
};


//------------------------------------------我的试卷------------------------------------------------------


// 查看历年考题数据列表
export const getHisteryList = (current, size) => {
  return request.post('/api/paper/one', {}, {
    params: {
      current,
      size
    },
  },)
};
// 查看历年题库试题数据列表
export const getHisteryData = (id, current, size) => {
  return request.post('/api/yearUtilsInfo/examInfo', {}, {
    params: {
      id,
      current,
      size,
    },
  },)
};

//查看计时考试数据列表
export const getTimerList = (current, size) => {
  return request.post('/api/paper/twe', {}, {
    params: {
      current,
      size
    },
  },)
};
//查看计时考试试题数据列表
export const getTimerData = (current, size, id) => {
  return request.post('/api/paper/selectTwe', {}, {
    params: {
      current,
      size,
      id,
    },
  },)
};
// export function getPractice() {
//   return request({
//     method: 'get',
//     url: '/api/practice/daily/info',
//     headers:{
//       'Content-Type': 'application/json;charset=UTF-8',
//       // "Content-Type":"application/json",
//       'appToken':getUserCode(),
//       'examTypeId':getExamType(),
//       'classifyId':getUtilsType(),
//     }
//   })
// }
// ---------------------------------------------------------历年考题结果错题------------------------------------------------------
//获取历年考题错题详情数据接口
export const getYearWrongInfoDetails = (examId) => {
  return request.post('/api/yearUtilsInfo/getWrongDetails', {}, {
    params: {
      examId
    },
  }
  )
};
export const getYearAllInfoDetails = (examId) => {
  return request.post('/api/yearUtilsInfo/getAllDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// --------------------------------------------------------结果查看详情---------------------------------------------------
//每日一练试题详情
export const practiceDetail = (questionId, resultId) => {
  return request.post('/api/practice/daily/details', {}, {
    params: {
      questionId,
      resultId
    },
  }

  )
};
// 计时考场试题详情
export const timerListDetail = (questionId, examId, scoresId) => {
  return request.post('/api/examRoom/details', {}, {
    params: {
      questionId,
      examId,
      scoresId
    },
  }
  )
};
// 历年考题试题详情
export const preListDetail = (questionId, examId) => {
  return request.post('/api/yearUtilsInfo/details', {}, {
    params: {
      questionId,
      examId
    },
  }
  )
};

// -----------------------------------------------------AI----------------------------------------------------
// 获取AI组题筛选条件数据列表接口
export const getAiInfo = () => {
  return request.post('/api/ai/info')
};
// 获取AI组题数据列表接口
export const getAiPaper = (data) => {
  return request.post('/api/ai/examPaper', data)
};
// 获取AI组题数据列表交卷接口
export const getAiSubmit = (data, examId) => {
  return request.post('/api/ai/submit', data, {
    params: {
      examId
    },
  }
  )
};
// 获取AI组题数据列表接口
export const getAiDetails = (questionId, examId, scoresId) => {
  return request.post('/api/ai/details', {}, {
    params: {
      questionId,
      examId,
      scoresId
    },
  }
  )
};
//  查看ai组题全部错题详情列表接口
export const getAiErrorDetails = (examId, scoresId) => {
  return request.post('/api/ai/getWrongDetails', {}, {
    params: {
      examId,
      scoresId
    },
  }
  )
};
// 获取AI组题数据列表接口
export const getAiAllDetails = (examId, scoresId) => {
  return request.post('/api/ai/getAllDetails', {}, {
    params: {
      examId,
      scoresId
    },
  }
  )
};
// 获取AI组题数据列表接口
export const getAiPage = (examId) => {
  return request.post('/api/ai/list', {}, {
    params: {
      examId
    },
  }
  )
};
// 大学生 获取AI组题数据列表接口
export const getAiPageStu = (examId) => {
  return request.post('/api/student/ai/list', {}, {
    params: {
      examId
    },
  }
  )
};



// 大学生ai
// 获取AI组题数据列表接口
export const getStudentAiInfo = () => {
  return request.post('/api/student/ai/info')
};
// 大学生ai
// 获取AI组题试卷数据列表接口
export const getStudentAiPaper = (data, examId) => {
  return request.post('/api/student/ai/examPaper', data, {
    params: {
      examId
    },
  }
  )
};
// 大学生ai
// 获取AI组题试卷数据交卷
export const getStudentAiPaperSubmit = (data, examId, questionType) => {
  return request.post('/api/student/ai/submit', data, {
    params: {
      examId,
      questionType
    },
  }
  )
};


// 大学生ai
// 获取AI组题数据列表接口
export const getStuAiDetails = (questionId, examId, scoresId) => {
  return request.post('/api/student/ai/details', {}, {
    params: {
      questionId,
      examId,
      scoresId,
    },
  }
  )
};
// 大学生ai
//  查看ai组题全部错题详情列表接口
export const getStuAiErrorDetails = (examId, scoresId) => {
  return request.post('/api/student/ai/getWrongDetails', {}, {
    params: {
      examId,
      scoresId
    },
  }
  )
};
// 大学生ai
// 获取AI组题数据列表接口
export const getStuAiAllDetails = (examId, scoresId) => {
  return request.post('/api/student/ai/getAllDetails', {}, {
    params: {
      examId,
      scoresId
    },
  }
  )
};
// ---------------------------------------------------new--------------------------------------------------
// 获取错题解析数据
export const getWrongDetails = (examId) => {
  return request.post('/api/practice/daily/getWrongDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// 获取 获取全部解析数据 
export const getAllDetails = (examId) => {
  return request.post('/api/practice/daily/getAllDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// ------------------------------------------------在校医学生--------------------------------------
// 获取知识科目试卷列表接口 
export const getStudentAll = (id, current, size) => {
  return request.post('/api/student/all', {}, {
    params: {
      id,
      current,
      size
    },
  }
  )
};
//  获取科目中的全部试题数据列表接口
export const getStudentExamInfo = (id, current, size) => {
  return request.post('/api/student/examInfo', {}, {
    params: {
      id,
      current,
      size
    },
  }
  )
};
//  获取科目中的全部试题数据列表接口
export const getStudentExamInfoX = (paperId, current, size) => {
  return request.post('/api/student/get/paperInfo', {}, {
    params: {
      paperId,
      current,
      size
    },
  }
  )
};
// 获取划重点选择分页列表数据接口
export const getUnderlinelist = () => {
  return request.post('/api/student/underline/list/new')
};
export const getStuAddSubject = (subjectId) => {
  return request.post('/api/student/underline/add/subject', {}, {
    params: {
      subjectId
    }
  })
};
// 获取选中科目试题列表数据接口
export const getUnderlineAll = (id, current, size) => {
  return request.post('/api/student/underline/all', {}, {
    params: {
      id,
      current,
      size,
    },
  }
  )
};
// 获取新刷题选中科目试题列表数据接口
export const getUnderlineInfo = (id) => {
  return request.post('/api/student/underline/info', {}, {
    params: {
      id,
    },
  }
  )
};
// 获取新刷题选中科目试题列表数据接口
export const getQuestionDetail = (id) => {
  return request.post('/api/student/underline/getQuestion', {}, {
    params: {
      id,
    },
  }
  )
};
// 获取新划重点信息搜索
export const getStuSearch = (keyword, subjectId) => {
  return request.post('/api/student/underline/search', {}, {
    params: {
      keyword,
      subjectId
    },
  }
  )
};
// 获取每日一练生成试题数据接口
export const getStuDailyInfo = () => {
  return request.post('/api/student/practice/daily/info')
};
// 在校医学生交卷数据列表接口
export const getStuDailySubmit = (userExamInfoDto) => {
  return request.post('/api/student/practice/daily/examInfo', userExamInfoDto)
};
// 在校医学生查看单题详情数据列表接口
export const getStuDailyDetails = (questionId, resultId) => {
  return request.post('/api/student/practice/daily/details', {}, {
    params: {
      questionId,
      resultId
    },
  }
  )
};
// 在校医学生查看单题详情数据列表接口
export const getStuDailyWrongDetails = (examId) => {
  return request.post('/api/student/practice/daily/getWrongDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// 在校医学生查看单题详情数据列表接口
export const getStuDailyAllDetails = (examId) => {
  return request.post('/api/student/practice/daily/getAllDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// 在校医学生查看单题详情数据列表接口
export const getStuPageSubmit = (data, subjectId, questionType) => {
  return request.post('/api/student/submit', data, {
    params: {
      subjectId,
      questionType
    },
  }
  )
};
// 在校医学生查看历年考题单题详情数据列表接口
export const getStuHisteryDetails = (questionId, examId) => {
  return request.post('/api/student/details', {}, {
    params: {
      questionId,
      examId
    },
  }
  )
};
// 在校医学生查看历年考题单题详情数据列表接口
export const getStuHisteryWrongDetails = (examId) => {
  return request.post('/api/student/getWrongDetails', {}, {
    params: {
      examId
    },
  }
  )
};
// 在校医学生查看历年考题单题详情数据列表接口
export const getStuHisteryAllDetails = (examId) => {
  return request.post('/api/student/getAllDetails', {}, {
    params: {
      examId
    },
  }
  )
};


// 我的题库全部解锁
export const getAllunlockList = (id) => {
  return request.post('/api/all/unlock/list', {}, {
    params: {
      id
    },
  }
  )
};
//-------------------------------------------------- 系统消息 ----------------------------------------
// 系统通知获取
export const getMessagesList = (current, size) => {
  return request.post('/api/messages/list', {}, {
    params: {
      current,
      size
    },
  }
  )
};
// 系统通知详情
export const getMessagesDetail = (id) => {
  return request.post('/api/messages/details', {}, {
    params: {
      id
    },
  }
  )
};
// 判断用户是否存在未读消息
export const getMessagesUnread = () => {
  return request.post('/api/messages/unread')
};
// 用户查询消息标记已读状态  参数 id
export const getMessagesMarkRead = (id) => {
  return request.post('/api/messages/markRead', {}, {
    params: {
      id
    },
  }
  )
};

// 用户全部标记已读状态
export const getMessagesMarkAllRead = () => {
  return request.post('/api/messages/markAllRead')
};







// 获取兑换规则
export const getRulesList = () => {
  return request.post('/api/redemption/rules/list')
};
// 记录知识答题记录
export const getYearSubmit = (questionId, answer, paperId, index, questionType) => {
  return request.post('/api/yearUtilsInfo/submit', {
    questionId,
    answer,
    paperId,
    index,
    questionType
  }
  )
};
// 大学生记录知识答题记录
export const getStuYearSubmit = (questionId, answer, paperId, index, questionType) => {
  return request.post('/api/student/answer', {
    questionId,
    answer,
    paperId,
    index,
    questionType
  }
  )
};
// 获取记录知识答题记录
export const getYearUserAnswer = (id, current, size) => {
  return request.post('/api/yearUtilsInfo/info', {}, {
    params: {
      id,
      current,
      size
    }
  }
  )
};
// 大学生获取记录知识答题记录
export const getStuYearUserAnswer = (id, current, size) => {
  return request.post('/api/student/info', {}, {
    params: {
      id,
      current,
      size
    }
  }
  )
};
// 重新作答，重置记录
export const getYearUserAnswerRetry = (paperId) => {
  return request.post('/api/yearUtilsInfo/retry', {}, {
    params: {
      paperId
    }
  }
  )
};
// 大学生，重新作答，重置记录
export const getStuYearUserAnswerRetry = (paperId) => {
  return request.post('/api/student/retry', {}, {
    params: {
      paperId
    },
  }
  )
};

