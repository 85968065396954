import router from "@/router";
// import { getUserCode, setUserCode, getExamType, getUtilsType, setRefToken, getRefToken } from "./storage";
import { getUserCode, setUserCode, getExamType, getUtilsType, } from "./storage";
import axios from "axios";
import { Toast } from "vant";
import { isToast } from "../api/library";
// import { getAppToken } from '../api/user'
// loading.js
export function showLoading() {
  // 清除之前的 setTimeout 计时器
  clearTimeout(showLoading.timeoutId);
  // 设置一个新的 setTimeout 计时器，0.5秒后执行 showLoading
  showLoading.timeoutId = setTimeout(() => {
    const app = document.querySelector('#app');
    const loadingContainer = app.querySelector('.loader');
    loadingContainer.style.display = 'block';
  }, 300);
}
export function hidddenLoading() {
  // 清除之前的 setTimeout 计时器
  clearTimeout(showLoading.timeoutId);
  const app = document.querySelector('#app');
  const loadingContainer = app.querySelector('.loader');
  loadingContainer.style.display = 'none';
}

// async function getToken() {
//   let accessToken = getRefToken()
//   await getAppToken(accessToken).then(res => {
//     if (res.code == 200) {
//       setUserCode(res.data)
//     }
//   })
// }

//创建实例
const instance = axios.create({
  // baseURL: 'http://test.channel.medicine.yujingm.cn',//基地址
  // baseURL: 'http://test.api.medicine.yujingm.cn',//基地址
  baseURL: 'http://api.medicine.yujingm.com', //基地址
  timeout: 10000,
});
// 请求头配置，例如设置Token
//自定义配置
instance.interceptors.request.use(function (config) {
  // const usercode = getUserCode() ? getUserCode() : ''
  const defaultHeaders = {
    'appToken': getUserCode(),
    'Client-Type': 'H5'

    // 可以根据需要添加更多的请求头
  }
  const typeExam = {
    "Content-Type": "application/json",
    'Examtypeid': getExamType(),
    'Classifyid': getUtilsType(),
    // 可以根据需要添加更多的请求头
  }
  const upLoad = {
    'Content-Type': 'multipart/form-data',
    'Examtypeid': getExamType(),
    'Classifyid': getUtilsType(),
  }
  const uploadImage = {
    'Content-Type': 'multipart/form-data'
  }
  const feedback = {
    'Content-Type': 'application/x-www-form-urlencoded'
    // 可以根据需要添加更多的请求头
  }
  const json = {
    'Content-Type': 'application/json',
    'Client-Type': 'H5'
    // 可以根据需要添加更多的请求头
  }
  const noTokenApi = [
    '/api/send',
    '/api/login',
    '/api/update',
    // '/api/updatePassword',
    '/api/loginPwd',
    '/api/wechat/pay/notify',
    '/api/wechat/jsApi/pay/login/callback',
    '/api/wechat/jsApi/pay/login/getOpenId',
    '/api/auth/wechat/login',
    '/api/wechat/pay/login/callback',
    '/api/auth/wechat/booleanLogin',
    '/api/carousel',
    '/api/token',
    '/api/library/allList',
    '/api/library/select',
    '/api/library/search',
    '/api/auth/wechat/h5/login',
    '/wechat/share/signature'
  ]
  // 获得题
  if (noTokenApi.includes(config.url)) {
    config.headers = { ...json };
  }
  else {
    switch (config.url) {
      case '/api/practice/daily/info':
        config.headers = { ...config.headers, ...defaultHeaders, ...typeExam };
        break;
      case '/api/feedback/images':
        config.headers = { ...config.headers, ...defaultHeaders, ...uploadImage };
        break;
      case '/api/feedback/submit':
        config.headers = { ...config.headers, ...defaultHeaders, ...feedback };
        break;
      case '/api/practice/daily/examInfo':
        config.headers = { ...config.headers, ...defaultHeaders, ...typeExam };
        break;
      case '/api/user/upload':
        config.headers = { ...config.headers, ...defaultHeaders, ...upLoad };
        break;
      default:
        if (getUserCode() !== '' && config.url !== '/api/practice/daily/info') {
          config.headers = { ...config.headers, ...defaultHeaders, ...typeExam };
        } else {
          config.headers = { ...config.headers, ...json };
        }
        break;
    }
  }
  // Toast.loading({
  //   message: '加载中...',
  //   forbidClick: true,
  //   loadingType: 'spinner',//配置loaing图标
  //   duration: 0, //不会自动消失
  // });
  showLoading()
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

//添加相应拦截器
// Add a response interceptor

instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const res = response.data

  if (res.code == 403) {
    localStorage.removeItem('yujing_user_code');
    if (router.currentRoute.path !== '/login' && getUserCode()) {
      Toast('登录已下线')
      router.push('/login')
    }
    hidddenLoading()
  }
  else if (res.code == 401) {
    if (!getUserCode()) {
      Toast('欢迎使用昱京医学')
    }
    if (router.currentRoute.path !== '/login') {
      router.push('/login')
    }
    hidddenLoading()
  }

  else if (res.code !== 200) {

    if (res.msg) {
      if (!isToast(response.config)) {
        Toast(res.msg)//错误提示
      }
    }
    else {
      // if (getUserCode()) {
      //   Toast('服务器异常')//错误提示
      // }
      if (!getUserCode()) {
        Toast('欢迎使用昱京医学')
      }
      if (router.currentRoute.path !== '/login') {
        router.push('/login')
      }
    }
    hidddenLoading()
    return Promise.reject(res)
  } else {
    //正确业务逻辑
    hidddenLoading()
  }
  return res;
}, function (error) {
  if (error.response.status == 500) {
    localStorage.removeItem('yujing_user_code');
  }
  hidddenLoading()
  Toast('服务器繁忙,请稍后...')
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
});


//实例导出

export default instance